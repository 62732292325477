import React from 'react'
import { Link } from 'gatsby'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Layout } from '../components/common'

import FundoObras from '../images/obras.jpg'
import FundoInteriores from '../images/interiores.jpg'
import FundoEventos from '../images/eventos.jpg'

const Index = ({ intl }) => (
    <>
        <Layout isHome={true}>
            <div className="container" >
                <section className="post-feed">

                    <Link to="/obras-de-arte" className="post-card">
                        <header className="post-card-header">
                            <div className="post-card-image" style={{ backgroundImage: `url(${FundoObras})`, borderRadius: `10px`, minHeight: `530px` }}></div>
                            <h2 className="post-card-title">{intl.formatMessage({ id: "obras" })}</h2>
                        </header>
                        <section className="post-card-excerpt">{intl.formatMessage({ id: "obrasTexto" })}</section>
                    </Link>

                    <Link to="/interiores" className="post-card">
                        <header className="post-card-header">
                            <div className="post-card-image" style={{ backgroundImage: `url(${FundoInteriores})`, borderRadius: `10px`, minHeight: `530px` }}></div>
                            <h2 className="post-card-title">{intl.formatMessage({ id: "interiores" })}</h2>
                        </header>
                        <section className="post-card-excerpt">{intl.formatMessage({ id: "interioresTexto" })}</section>
                    </Link>

                    <Link to="/eventos" className="post-card">
                        <header className="post-card-header">
                            <div className="post-card-image" style={{ backgroundImage: `url(${FundoEventos})`, borderRadius: `10px`, minHeight: `530px` }}></div>
                            <h2 className="post-card-title">{intl.formatMessage({ id: "eventos" })}</h2>
                        </header>
                        <section className="post-card-excerpt">{intl.formatMessage({ id: "eventosTexto" })}</section>
                    </Link>
                </section>

            </div>
            <br/><br/><br/>
        </Layout>
    </>
)

export default injectIntl(Index)

